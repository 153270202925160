<template>
  <v-dialog
    max-width="800"
    :value="showDialog"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
	  <v-card-subtitle class="mt-1">
        {{ subTitle }}
	  </v-card-subtitle>
      <v-card-text>
        <v-textarea
		  auto-grow
          v-model="textAreaValue"
		  :readonly="disabled"
		  :class="{ 'custom-cursor': disabled }"
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="error"
          @click="close"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
		  v-if="!disabled"
          @click="updateValue"
        >
          {{ $t('Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "TextAreaDialog",
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},
		textValue: {
			type: String,
			default: null
		},
		title: {
			type: String,
			default: null
		},
		subTitle: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	emits: {
		updateValue: (text) => {
			// Here we can validate what is being emitted, if we want or need to
			return text
		},
		close: null
	},
	data() {
		return {
			textAreaValue: null
		}
	},
	mounted() {
		if(typeof this.textValue === 'string') {
			this.textAreaValue = this.textValue
		}
	},
	methods: {
		updateValue() {
			this.$emit('updateValue', this.textAreaValue)
			this.$emit('close')
		},
		close() {
			this.$emit('close')
		}
	}
}
</script>

<style scoped>

.custom-cursor {
	pointer-events: none;
  	cursor: auto; /* or any other cursor style you want */
}

</style>